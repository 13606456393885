import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { CssBaseline } from "@material-ui/core";
import { store } from "./store";
import { LicenseInfo } from "@mui/x-license";
import { MUI_LICENSE_KEY } from "./constants";
import { initializeSelectedProposal } from './reducers';

store.dispatch(initializeSelectedProposal());
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
