import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox, Typography } from "@mui/material";
import { selectDuplicateProposalAndRefreshProposals, refreshLogs } from "../../reducers";
import { decoratedFetch } from "../../req_utils";
import { SUPPORT_EMAIL } from "../../constants";
import { createAutogeneratedLog } from "../../log_utils";

const DuplicateProposalDialog = ({
  open,
  handleClose: handleCloseProp,
  proposalId,
  proposalName: proposalNameProp,
}) => {
  const dispatchState = useDispatch();
  const project = useSelector((state) => state.project);
  const employee = useSelector((state) => state.employee);
  const [proposalName, setProposalName] = useState(`${proposalNameProp} Duplicate`);
  const [alertMessage, setAlertMessage] = useState("");
  const [proposalNameError, setProposalNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProposalName(`${proposalNameProp} Duplicate`);
  }, [proposalNameProp]);

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    handleCloseProp();
  };

  const handleSubmit = async () => {
    if (!proposalName) {
      setProposalNameError(true);
      return;
    }

    decoratedFetch('/duplicate_proposal', {
      method: "POST",
      body: JSON.stringify({
        proposal_id: proposalId,
        new_proposal_name: proposalName,
      }),
    })
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        }
        throw new Error();
      })
      .then(data => {
        if (!data?.proposal) {
          throw new Error("Error occurred");
        }
        setLoading(false);
        dispatchState(selectDuplicateProposalAndRefreshProposals(data.proposal));
        createAutogeneratedLog(
          project?.id,
          employee?.id,
          `Proposal \"${proposalName}\" created by ${employee.first_name} ${employee.last_name}`,
        )
          .then((response) => {
            if (response.status !== 201) {
              throw new Error("Error occurred");
            }
            dispatchState(refreshLogs(true));
          })
          .catch((error) => {
            console.error(`Error creating log: ${error}`);
          });
        handleCloseProp(/*duplicated=*/true);
      })
      .catch((error) => {
        setAlertMessage(
          `Unknown error. If this persists, please contact ${SUPPORT_EMAIL}`,
        );
        console.error(
          `Error duplicating proposal: ${error}`,
        );
        setLoading(false);
      });
  };

  const onProposalNameChange = (newName) => {
    setProposalNameError(false);
    setProposalName(newName);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      disableEscapeKeyDown
      disableScrollLock
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        Duplicate Proposal
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          sx={{ mt: '5px' }}
        >
          {alertMessage && (
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Alert
                onClose={() => setAlertMessage("")}
                severity={"error"}
                sx={{
                  width: "80%",
                  borderRadius: 8,
                  marginBottom: "15px",
                }}
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label="Proposal Name"
              variant="outlined"
              fullWidth
              autoFocus
              size="small"
              value={proposalName}
              onChange={e => onProposalNameChange(e.target.value)}
              error={proposalNameError}
              helperText={proposalNameError ? "Required" : ""}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pt: "15px" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleSubmit}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateProposalDialog;
