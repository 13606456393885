import React, { useState, useEffect } from "react";
import {
  Modal,
  Fade,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import { decoratedFetch } from "../../req_utils";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

export function CreateProjectModal({ isOpen, handleClose, content }) {
  const navigate = useNavigate();
  const [account_id, setAccount] = useState("new_account");
  const [accounts, setAccounts] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [project, setProjectName] = useState("");
  const [addressLn1, setAddressLn1] = useState("");
  const [addressLn2, setAddressLn2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [salesEmployee, setSalesEmployee] = useState("");
  const [employees, setEmployees] = useState([]);

  const handleSalesEmployeeChange = (event) =>
    setSalesEmployee(event.target.value);
  const handleAccountChange = (event) => setAccount(event.target.value);
  const handleAccountNameChange = (event) => setAccountName(event.target.value);
  const handleProjectChange = (event) => setProjectName(event.target.value);
  const handleAddressLn1Change = (event) => setAddressLn1(event.target.value);
  const handleAddressLn2Change = (event) => setAddressLn2(event.target.value);
  const handleCityChange = (event) => setCity(event.target.value);
  const handleStateChange = (event) => setState(event.target.value);
  const handleZipChange = (event) => setZip(event.target.value);
  const [accountType, setAccountType] = useState("");
  const [accountTypes, setAccountTypes] = useState([]);

  const handleAccountTypeChange = (event) => setAccountType(event.target.value);
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    decoratedFetch("/list_accounts")
      .then((response) => response.json())
      .then((data) => {
        // Check if 'data' is an array before updating state
        if (Array.isArray(data)) {
          setAccounts(data);
        } else {
          console.error("Expected an array but got:", data);
          setAccounts([]); // Set to an empty array or handle as appropriate
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setAccounts([]); // Set to an empty array in case of error
      });
    decoratedFetch("/list_employees")
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => setEmployees(data.filter((employee) => employee.enabled)))
      .catch((error) => {
        console.error(`Error listing employees: ${error}`);
      });
    decoratedFetch("/list_account_types")
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' should be an array of contact types, check if it's actually an array
        if (Array.isArray(data)) {
          setAccountTypes(data);
        } else {
          console.error("Expected an array of contact types, received:", data);
          setAccountTypes([]); // Set to an empty array if the response is not as expected
        }
      })
      .catch((error) => {
        console.error("Error fetching contact types:", error);
        setAccountTypes([]); // Set to an empty array in case of an error
      });
  }, [isOpen]);

  const handleSend = () => {
    if (!project || !salesEmployee) {
      setSeverity("error");
      setAlertMessage(
        "Missing required project fields",
      );
      setLoading(false);
      return;
    }

    setLoading(true);

    const url_suffix = "/create_project";

    let payload;
    if (account_id === 'new_account') {
      if (!accountName || !accountType) {
        setSeverity("error");
        setAlertMessage(
          "Missing required account fields",
        );
        setLoading(false);
        return;
      }

      payload = {
        name: project,
        sales_employee: salesEmployee,
        account: {
          name: accountName,
          account_type_id: accountType,
        },
      };
    } else {
      payload = {
        account_id: account_id,
        name: project,
        sales_employee: salesEmployee,
      };
    }
    decoratedFetch(url_suffix, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 201) {
          return response.json();
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        setLoading(false);
        navigate(`/project/${data.project_id}`);
      })
      .catch((error) => {
        console.error("Error:", error);
        setSeverity("error");
        setLoading(false);
      });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage("");
  };

  return (
    <Modal
      disableScrollLock
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={isOpen}
      onClose={() => {
        handleClose();
        setAlertMessage(""); // Also clear success message when closing
      }}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          {alertMessage && (
            <Alert
              onClose={handleAlertClose}
              severity={severity}
              sx={{ width: "100%", mb: 2 }}
            >
              {alertMessage}
            </Alert>
          )}
          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            <Typography variant="h5" id="transition-modal-title" sx={{ mb: '10px' }}>
              Create Project
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FormControl
                  variant="outlined"
                  sx={{
                    minWidth: 120,
                    width: "100%",
                  }}
                >
                  <Select
                    labelId="account-select-label"
                    id="account-select"
                    displayEmpty
                    value={account_id}
                    onChange={(event) => setAccount(event.target.value)} // Updates account_id
                    fullWidth
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                          overflowY: "auto",
                        },
                      },
                    }}
                    renderValue={(selectedId) => {
                      if (selectedId === 'new_account') {
                        return (
                          <Typography sx={{ fontStyle: "italic", fontSize: "15.5px" }}>
                            Create New Account
                          </Typography>
                        );
                      }
                      const selectedAccount = accounts.find((account) => account.id === selectedId);
                      return selectedAccount?.name || "";
                    }}
                  >
                    <MenuItem key="new_account" value="new_account">
                      <em>Create New Account</em>
                    </MenuItem>
                    {accounts.map((account) => (
                      <MenuItem key={account.id} value={account.id}>
                        {account.name}
                      </MenuItem>
                    ))}
                  </Select>

                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Project Name"
                  type="project name"
                  fullWidth
                  variant="outlined"
                  value={project}
                  onChange={handleProjectChange}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <InputLabel>Sales Rep.</InputLabel>
                  <Select
                    id="sales-select"
                    value={salesEmployee}
                    onChange={handleSalesEmployeeChange}
                    label="Sales Rep."
                    fullWidth
                    MenuProps={{
                      disableScrollLock: true,
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                          overflowY: "auto",
                        },
                      },
                    }}
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee.id} value={employee}>
                        {employee.first_name} {employee.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {account_id === "new_account" && (
              <>
                <Typography variant="h6" sx={{ mt: '10px' }}>New Account</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Account Name"
                      type="account name"
                      fullWidth
                      variant="outlined"
                      value={accountName}
                      onChange={handleAccountNameChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="account-type-label">Account Type</InputLabel>
                      <Select
                        labelId="account-type-label"
                        id="Account Type"
                        value={accountType}
                        label="Account Type"
                        onChange={handleAccountTypeChange}
                        required
                      >
                        {accountTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                paddingTop: 10,
              }}
            >
              {loading ? (
                <CircularProgress
                  size="25px"
                  sx={{
                    textAlign: "center",
                    mt: "6px",
                    mb: "5.5px",
                    mr: "20px",
                    ml: "auto",
                  }}
                />
              ) : (
                <>
                  <Button
                    style={{ marginRight: 10 }}
                    variant="outlined"
                    onClick={handleClose}
                    disabled={alertMessage}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    disabled={alertMessage}
                  >
                    Create
                  </Button>
                </>
              )}
            </div>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
}
