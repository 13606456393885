import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {
  updateSelectedProposal,
  refreshFiles,
} from "../reducers";
import { decoratedFetch } from "../req_utils";
import { GENESIS_LOGO_COLOR } from "../constants";

function QuoteBuilder() {
  const selectedProposal = useSelector((state) => state.selectedProposal);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    material_subtotal: 0,
    material_markup_rate: "",
    material_sales_tax_rate: "",
    material_total: 0,
    labor_subtotal: 0,
    labor_markup_rate: "",
    labor_sales_tax_rate: "",
    labor_total: 0,
    freight_subtotal: 0,
    subtotal_job_cost: 0,
    total_job_cost: 0,
    p_and_p_bond: 0,
    approximate_bid_price: 0,
    proposal_total: "",
    consultant_fee_rate: "",
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const updateProposal = fieldsToUpdate => {
    decoratedFetch(`/update_proposal/${selectedProposal.id}`, {
      method: "PUT",
      body: JSON.stringify(fieldsToUpdate),
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Error message generated");
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to save proposal");
        console.error(`Error updating proposal: ${error}`);
      });
  };

  const valuesFromProposal = () => {
    return {
      material_subtotal:
        selectedProposal.material_subtotal + selectedProposal.freight_subtotal,
      material_markup_rate: parseFloat(
        selectedProposal.material_markup_rate,
      ).toFixed(2),
      material_sales_tax_rate: parseFloat(
        selectedProposal.material_sales_tax_rate,
      ).toFixed(2),
      material_total: parseFloat(
        (
          (selectedProposal.material_subtotal +
            selectedProposal.freight_subtotal) *
          (1 + selectedProposal.material_markup_rate / 100) *
          (1 + selectedProposal.material_sales_tax_rate / 100)
        ).toFixed(2),
      ),
      labor_subtotal: selectedProposal.labor_subtotal,
      labor_markup_rate: parseFloat(selectedProposal.labor_markup_rate).toFixed(
        2,
      ),
      labor_sales_tax_rate: parseFloat(
        selectedProposal.labor_sales_tax_rate,
      ).toFixed(2),
      labor_total: parseFloat(
        (
          selectedProposal.labor_subtotal *
          (1 + selectedProposal.labor_markup_rate / 100) *
          (1 + selectedProposal.labor_sales_tax_rate / 100)
        ).toFixed(2),
      ),
      p_and_p_bond: parseFloat(selectedProposal.p_and_p_bond).toFixed(2),
      proposal_total: parseFloat(selectedProposal.proposal_total).toFixed(2),
      consultant_fee_rate: parseFloat(
        selectedProposal.consultant_fee_rate,
      ).toFixed(2),
    };
  };

  useEffect(() => {
    const proposalPresent =
      selectedProposal &&
      Object.keys(selectedProposal).length !== 0;
    if (!proposalPresent) {
      return;
    }

    let newValues = valuesFromProposal();
    newValues.subtotal_job_cost = parseFloat(
      (newValues.material_total + newValues.labor_total).toFixed(2),
    );
    newValues.total_job_cost = parseFloat(
      (
        newValues.subtotal_job_cost *
        (1 + newValues.consultant_fee_rate / 100)
      ).toFixed(2),
    );
    newValues.approximate_bid_price = parseFloat(
      (newValues.total_job_cost + parseFloat(newValues.p_and_p_bond)).toFixed(
        2,
      ),
    );
    setValues(newValues);
  }, [selectedProposal]);

  const handleBlur = (event, field) => {
    let value = event.target.value.replace(/[^\d.]/g, "");
    value = parseFloat(value || 0).toFixed(2);
    const fieldsToUpdate = {
      [field]: parseFloat(value),
    };
    dispatch(updateSelectedProposal(fieldsToUpdate));
    updateProposal(fieldsToUpdate);
  };

  const handleKeyDown = (event, field) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
      <Typography
        variant="h6"
        align="center"
        sx={{ display: { xs: 'none', lg: 'block' }, mt: { xs: "15px", lg: "0px" }, pb: 2 }}
      >
        Quote Builder
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="material-cost-table">
          <TableBody>
            <TableRow key="material_subtotal">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: "bold", width: "160px", pr: 0 }}
              >
                Material & Freight
              </TableCell>
              <TableCell align="right" sx={{ pl: 0 }}>
                <TextField
                  value={values["material_subtotal"].toFixed(2)}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="material_markup_rate">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: "smaller", pl: 4, pr: 0, width: "80px" }}
              >
                Markup Rate
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "smaller" }}>
                <TextField
                  value={
                    values["material_markup_rate"] > 0
                      ? values["material_markup_rate"]
                      : ""
                  }
                  placeholder="0.00"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      material_markup_rate: e.target.value.replace(
                        /[^\d.]/g,
                        "",
                      ),
                    })
                  }
                  onBlur={(e) => handleBlur(e, "material_markup_rate")}
                  onKeyDown={(e) => handleKeyDown(e, "material_markup_rate")}
                  size="small"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginLeft: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-2px", fontSize: "14px" }}>
                          %
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  inputProps={{
                    sx: { fontSize: "14px", padding: 0, textAlign: "right" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="material_sales_tax_rate">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: "smaller", pl: 4, pr: 0, width: "80px" }}
              >
                Sales Tax Rate
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "smaller" }}>
                <TextField
                  value={
                    values["material_sales_tax_rate"] > 0
                      ? values["material_sales_tax_rate"]
                      : ""
                  }
                  placeholder="0.00"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      material_sales_tax_rate: e.target.value.replace(
                        /[^\d.]/g,
                        "",
                      ),
                    })
                  }
                  onBlur={(e) => handleBlur(e, "material_sales_tax_rate")}
                  onKeyDown={(e) => handleKeyDown(e, "material_sales_tax_rate")}
                  size="small"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginLeft: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-2px", fontSize: "14px" }}>
                          %
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  inputProps={{
                    sx: { fontSize: "14px", padding: 0, textAlign: "right" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="material_total">
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontWeight: "bold",
                  fontSize: "smaller",
                  pl: 4,
                  pr: 0,
                  width: "140px",
                }}
              >
                Total
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "smaller" }}>
                <TextField
                  value={values["material_total"].toFixed(2)}
                  size="small"
                  variant="standard"
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} sx={{ marginTop: "15px" }}>
        <Table size="small" aria-label="labor-cost-table">
          <TableBody>
            <TableRow key="labor_subtotal">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: "bold", width: "160px", pr: 2 }}
              >
                Labor
              </TableCell>
              <TableCell align="right" sx={{ paddingLeft: 0 }}>
                <TextField
                  value={values["labor_subtotal"].toFixed(2)}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="labor_markup_rate">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: "smaller", pl: 4, pr: 0, width: "80px" }}
              >
                Markup Rate
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "smaller" }}>
                <TextField
                  value={
                    values["labor_markup_rate"] > 0
                      ? values["labor_markup_rate"]
                      : ""
                  }
                  placeholder="0.00"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      labor_markup_rate: e.target.value.replace(/[^\d.]/g, ""),
                    })
                  }
                  onBlur={(e) => handleBlur(e, "labor_markup_rate")}
                  onKeyDown={(e) => handleKeyDown(e, "labor_markup_rate")}
                  size="small"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginLeft: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-2px", fontSize: "14px" }}>
                          %
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  inputProps={{
                    sx: { fontSize: "14px", padding: 0, textAlign: "right" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="labor_sales_tax_rate">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: "smaller", pl: 4, pr: 0, width: "80px" }}
              >
                Sales Tax Rate
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "smaller" }}>
                <TextField
                  value={
                    values["labor_sales_tax_rate"] > 0
                      ? values["labor_sales_tax_rate"]
                      : ""
                  }
                  placeholder="0.00"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      labor_sales_tax_rate: e.target.value.replace(
                        /[^\d.]/g,
                        "",
                      ),
                    })
                  }
                  onBlur={(e) => handleBlur(e, "labor_sales_tax_rate")}
                  onKeyDown={(e) => handleKeyDown(e, "labor_sales_tax_rate")}
                  size="small"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginLeft: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-2px", fontSize: "14px" }}>
                          %
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  inputProps={{
                    sx: { fontSize: "14px", padding: 0, textAlign: "right" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="labor_total">
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontWeight: "bold",
                  fontSize: "smaller",
                  pl: 4,
                  pr: 0,
                  width: "140px",
                }}
              >
                Total
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "smaller" }}>
                <TextField
                  value={values["labor_total"].toFixed(2)}
                  size="small"
                  variant="standard"
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} sx={{ marginTop: "15px" }}>
        <Table size="small" aria-label="total-costs-table">
          <TableBody>
            <TableRow key="subtotal_job_cost">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: "bold", width: "160px", pr: 2 }}
              >
                Subtotal
              </TableCell>
              <TableCell align="right" sx={{ paddingLeft: 0 }}>
                <TextField
                  value={values["subtotal_job_cost"].toFixed(2)}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="consultant-fee-rate">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: "smaller", pl: 4, pr: 0, width: "80px" }}
              >
                Consultant Fee
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "smaller" }}>
                <TextField
                  value={
                    values["consultant_fee_rate"] > 0
                      ? values["consultant_fee_rate"]
                      : ""
                  }
                  placeholder="0.00"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      consultant_fee_rate: e.target.value.replace(
                        /[^\d.]/g,
                        "",
                      ),
                    })
                  }
                  onBlur={(e) => handleBlur(e, "consultant_fee_rate")}
                  onKeyDown={(e) => handleKeyDown(e, "consultant_fee_rate")}
                  size="small"
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ marginLeft: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-2px", fontSize: "14px" }}>
                          %
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                  }}
                  inputProps={{
                    sx: { fontSize: "14px", padding: 0, textAlign: "right" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="total_job_cost">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: "bold", width: "160px", pr: 2 }}
              >
                Total Job Cost
              </TableCell>
              <TableCell align="right" sx={{ paddingLeft: 0 }}>
                <TextField
                  value={values["total_job_cost"].toFixed(2)}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="p_and_p_bond">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: "smaller", width: "160px", pl: 4, pr: 2 }}
              >
                Performance Bond
              </TableCell>
              <TableCell align="right" sx={{ pl: 0 }}>
                <TextField
                  value={
                    values["p_and_p_bond"] > 0 ? values["p_and_p_bond"] : ""
                  }
                  placeholder="0.00"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      p_and_p_bond: e.target.value.replace(/[^\d.]/g, ""),
                    })
                  }
                  onBlur={(e) => handleBlur(e, "p_and_p_bond")}
                  onKeyDown={(e) => handleKeyDown(e, "p_and_p_bond")}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: false,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow key="approx_bid_price">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: "bold", width: "160px", pr: 2 }}
              >
                Approx Bid Price
              </TableCell>
              <TableCell align="right" sx={{ pl: 0 }}>
                <TextField
                  value={values["approximate_bid_price"].toFixed(2)}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100px" }}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} sx={{ marginTop: "15px" }}>
        <Table size="small" aria-label="proposal-total-table">
          <TableBody>
            <TableRow key="proposal_total">
              <TableCell
                component="th"
                scope="row"
                sx={{ fontWeight: "bold", width: "160px", pr: 2 }}
              >
                Proposal Price
              </TableCell>
              <TableCell align="right" sx={{ pl: 0 }}>
                <TextField
                  value={
                    values["proposal_total"] > 0 ? values["proposal_total"] : ""
                  }
                  placeholder="0.00"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      proposal_total: e.target.value.replace(/[^\d.]/g, ""),
                    })
                  }
                  onBlur={(e) => handleBlur(e, "proposal_total")}
                  onKeyDown={(e) => handleKeyDown(e, "proposal_total")}
                  size="small"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: "100px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ marginRight: "4px", color: "black" }}
                      >
                        <span style={{ marginTop: "-3px", fontSize: "14px" }}>
                          $
                        </span>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    sx: { fontSize: "14px" },
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default QuoteBuilder;
